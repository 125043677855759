import React from "react";
import Index from "../views/PublicSector/Index";
import Layout from "../components/Layout";

function PublicSector({location}) {
  return (
    <div>
 <Layout location={location}>
        <Index  location={location}/>
      </Layout>
    </div>
  );
}

export default PublicSector;
