import React from "react";
import Careersatvonage from "./Careersatvonage";
import Header from "./Header";
import Speak from "./../../components/Speak";
import Cards from "./Cards";
import Features from "./Features";
import data from "./../../data";

function Index({location}) {
  return (
    <div>
      <Header />
      <Features />
      <Careersatvonage />
      <Cards card3={data.card3} />
      <Speak  location={location}/>
    </div>
  );
}

export default Index;
