import React from "react";
import Headerp from "./../../images/GettyImages-1146062939.jpg";

function Header() {
  return (
    <div className="header-gradient">
      <div className="max-w-7xl m-auto md:py-24 py-12 px-8">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-10">
          <div>
            <div className="mb-2">
              <span className="font-medium text-xs text-white"> CAREERS</span>
            </div>
            <h1 className=" font-medium text-white text-5xl mb-4">
            Education Solutions
            </h1>
            <p className="text-base font-normal text-white mb-5">
            From video lectures to virtual classrooms to mobile tutoring, Vonage’s cloud-based communications help digital education platforms, learning institutions, and teachers meet the challenges of remote
             learning by staying connected and sharing knowledge, no matter where they are.
             </p>
             <div className="pt-16">
             <a
                  href="/"
                  className="bg-white border-white border-2 text-black px-8 mb-6 mr-6 py-6 rounded-lg"
                >
                 Contact an expert
                </a>
             </div>
          </div>
          <div className="justify-self-center">
            <img src={Headerp} alt="" className="w-full" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
